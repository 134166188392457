// ** Mui Imports
import {
  Box,
  Card,
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// ** Type Imports
import { Model, User } from '@/types'

// ** Utils Imports
import dayjs from 'dayjs'

// ** Type Imports
import type { UserParmas } from '@/types/user'

const defaultColumns: GridColumns = [
  {
    width: 100,
    field: 'id',
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    width: 500,
    field: 'email',
    headerAlign: 'center',
    headerName: '아이디',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.email}</Typography>
      </Box>
    ),
  },
  {
    width: 100,
    field: 'status',
    headerAlign: 'center',
    headerName: '상태',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        {row.status === 'ACTIVE' && (
          <Typography variant="body2">활성화</Typography>
        )}
        {row.status === 'WAIT' && <Typography variant="body2">대기</Typography>}
        {row.status === 'INACTIVE' && (
          <Typography variant="body2">정지</Typography>
        )}
        {row.status === 'DELETE' && (
          <Typography variant="body2">탈퇴</Typography>
        )}
      </Box>
    ),
  },
  {
    width: 100,
    field: 'type',
    headerAlign: 'center',
    headerName: '가입 형태',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.socialType}</Typography>
      </Box>
    ),
  },
  {
    width: 100,
    field: 'lang',
    headerAlign: 'center',
    headerName: '언어',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.lang}</Typography>
      </Box>
    ),
  },
  {
    width: 100,
    field: 'os',
    headerAlign: 'center',
    headerName: '플랫폼',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.os}</Typography>
      </Box>
    ),
  },
  {
    width: 150,
    field: 'model',
    headerAlign: 'center',
    headerName: '모델',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {row.currentUserModelName ? row.currentUserModelName : '-'}
        </Typography>
      </Box>
    ),
  },
  {
    width: 200,
    field: 'date',
    headerAlign: 'center',
    headerName: '가입일',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
  {
    width: 200,
    field: 'last_login_date',
    headerAlign: 'center',
    headerName: '최근 접속',
    renderCell: ({ row }: { row: User }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {row.lastAccessTime
            ? dayjs(row.lastAccessTime).format('YYYY.MM.DD HH:mm:ss')
            : '-'}
        </Typography>
      </Box>
    ),
  },
]

interface Props {
  data: User[]
  page: number
  count: number
  query: UserParmas
  setQuery: (e: any) => void
  handleRefetch: () => void
  handleInitQuery: () => void
  handleExcelDownload: () => void
  setPage: any
  modelData: Model[]
}

const UserPageView = ({
  data,
  page,
  count,
  handleRefetch,
  setPage,
  query,
  setQuery,
  modelData,
  handleInitQuery,
  handleExcelDownload,
}: Props) => {
  const columns = [...defaultColumns]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="status">상태</InputLabel>
                <Select
                  labelId="status"
                  label="상태"
                  value={query.type}
                  name="type"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="ACTIVE">활성화</MenuItem>
                  <MenuItem value="WAIT">대기</MenuItem>
                  <MenuItem value="DELETE">탈퇴</MenuItem>
                  <MenuItem value="INACTIVE">정지</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="social">가입 형태</InputLabel>
                <Select
                  labelId="social"
                  label="가입 형태"
                  value={query.socialType}
                  name="socialType"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="LOCAL">Local</MenuItem>
                  <MenuItem value="FACEBOOK">Facebook</MenuItem>
                  <MenuItem value="GOOGLE">Google</MenuItem>
                  <MenuItem value="APPLE">Apple</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="lang">언어</InputLabel>
                <Select
                  labelId="lang"
                  label="언어"
                  value={query.lang}
                  name="lang"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="en">en</MenuItem>
                  <MenuItem value="fr">fr</MenuItem>
                  <MenuItem value="de">de</MenuItem>
                  <MenuItem value="es">es</MenuItem>
                  <MenuItem value="lt">lt</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="os">플랫폼</InputLabel>
                <Select
                  labelId="os"
                  label="플랫폼"
                  value={query.osType}
                  name="osType"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="AOS">AOS</MenuItem>
                  <MenuItem value="iOS">iOS</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="model">모델</InputLabel>
                <Select
                  labelId="model"
                  label="모델"
                  value={query.modelId}
                  name="modelId"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  {modelData.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="아이디 검색"
                placeholder="검색할 정보를 입력하세요."
                value={query.email}
                name="email"
                onChange={setQuery}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleRefetch}
              >
                조회
              </Button>
            </Grid>
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                color="error"
                size="large"
                fullWidth
                onClick={handleInitQuery}
              >
                초기화
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              sx={{ mb: 2, ml: 10 }}
              color="success"
              onClick={handleExcelDownload}
            >
              엑셀 다운로드
            </Button>
          </Box>

          <DataGrid
            columns={columns}
            rows={data}
            rowCount={count}
            autoHeight
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnMenu
            pagination
            page={page}
            paginationMode="server"
            pageSize={10}
            onPageChange={(newPage) => {
              setPage(newPage)
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  등록된 정보가 없습니다.
                </Stack>
              ),
            }}
            columnBuffer={5}
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                minWidth: '100%',
                overflowX: 'auto',
              },
            }}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default UserPageView
