// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import MailPageView from './mail-page'

// ** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindUserListAllQuery,
  useLazyFindUserListQuery,
  usePushMailMutation,
} from '../../services'

// ** Type Imports
import { User } from '@/types'
import { Push, type PushUserParams } from '@/types/push'

// ** Utils Imports
import { isUndefined } from 'lodash'
import useInput from '../../hooks/useInput'

// ** Editor Imports
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

const MailPage = () => {
  const {
    data: query,
    handler: setQuery,
    setData,
  } = useInput<PushUserParams>({
    email: '',
    type: '',
    socialType: '',
    lang: '',
    osType: '',
    modelId: 0,
  })

  const {
    data: push,
    handler: setPush,
    setData: setPushData,
  } = useInput<Push>({
    title: '',
    sender: process.env.REACT_APP_EMAIL_SENDER || '',
    image: '',
  })

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )

  const [page, setPage] = useState<number>(0)
  const [page2, setPage2] = useState<number>(0)
  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)
  const [cancelOpen, setCancelOpen] = useState<boolean>(false)

  const [userList, setUserList] = useState<User[]>([])
  const [selectUserList, setSelectUserList] = useState<User[]>([])
  const [count, setCount] = useState<number>(0)

  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [selectedRows2, setSelectedRows2] = useState<number[]>([])

  const args = () => {
    const arg: {
      page: number
      pageSize: number
      email?: string
      socialType?: string
      lang?: string
      type?: string
      osType?: string
      modelId?: number
    } = {
      page,
      pageSize: 10,
    }
    if (query.type !== '') arg.type = query.type
    if (query.email !== '') arg.email = query.email
    if (query.socialType !== '') arg.socialType = query.socialType
    if (query.lang !== '') arg.lang = query.lang
    if (query.osType !== '') arg.osType = query.osType
    if (query.modelId !== 0) arg.modelId = query.modelId

    return arg
  }

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const [findUserApi] = useLazyFindUserListQuery()
  const [findUserAllApi] = useLazyFindUserListAllQuery()
  const [pushApi] = usePushMailMutation()

  const handleRefetch = () => setReRenderSwitch(true)
  const handleInitQuery = () => {
    setData({
      email: '',
      type: '',
      socialType: '',
      lang: '',
      osType: '',
      modelId: 0,
    })
    handleRefetch()
  }

  const handleOpen = () => setOpen(true)
  const handleCancelOpen = () => setCancelOpen(true)
  const handleClose = () => setOpen(false)
  const handleCancelClose = () => setCancelOpen(false)

  const handleEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState)
  }

  const handleAddSelectUserList = () => {
    const arr: User[] = userList
      .filter((item) => selectedRows.includes(item.id))
      .filter((item) => !selectUserList.includes(item))

    setSelectUserList((cur) => [...cur, ...arr])

    setSelectedRows([])
  }
  const handleRemoveSelectUserList = () => {
    setSelectUserList((cur) =>
      cur.filter((item) => !selectedRows2.includes(item.id)),
    )

    setSelectedRows2([])
  }

  const handleSelectAll = () => {
    findUserAllApi(args())
      .unwrap()
      .then((res) => {
        setSelectUserList(res.data.data)

        setSelectedRows([])
      })
  }

  const handleUnSelectAll = () => {
    setSelectUserList([])
    setSelectedRows2([])
    setPage2(0)
  }

  const handleInit = () => {
    handleClose()
    handleCancelClose()
    setSelectedRows2([])
    setSelectedRows([])
    setSelectUserList([])
    setData({
      email: '',
      type: '',
      socialType: '',
      lang: '',
      osType: '',
      modelId: 0,
    })
    setPushData({
      title: '',
      sender: process.env.REACT_APP_EMAIL_SENDER || '',
      image: '',
    })
    setEditorState(EditorState.createEmpty())
  }

  const handlePushMail = () => {
    if (selectUserList.length < 1) {
      alert('1명 이상의 유저를 선택해주세요.')
      handleClose()

      return
    }

    if (push.sender === '') {
      alert('보내는 이를 입력하세요.')
      handleClose()

      return
    }

    if (push.title === '') {
      alert('제목을 입력하세요.')
      handleClose()

      return
    }

    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    if (text.replace(/\s+/g, '') === '<p></p>'.replace(/\s+/g, '')) {
      alert('내용을 입력하세요.')
      handleClose()

      return
    }

    pushApi({
      ...push,
      text,
      userEmailList: selectUserList.map((item) => item.email),
    })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('메일 발송에 성공하였습니다.')
          handleInit()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  const handleFilePath = (image: string) => {
    setPushData((cur: any) => ({ ...cur, image }))
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findUserApi(args())
      .unwrap()
      .then((res) => {
        setUserList(res.data.data)
        setCount(res.data.count)
      })
  }, [reRenderSwitch, page])

  return (
    <MailPageView
      data={userList}
      selectData={selectUserList}
      handleRefetch={handleRefetch}
      page={page}
      setPage={setPage}
      count={count}
      push={push}
      setPush={setPush}
      query={query}
      setQuery={setQuery}
      modelData={modelData}
      handleInitQuery={handleInitQuery}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectedRows2={selectedRows2}
      setSelectedRows2={setSelectedRows2}
      handleAddSelectUserList={handleAddSelectUserList}
      handleRemoveSelectUserList={handleRemoveSelectUserList}
      handlePushMail={handlePushMail}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleCancelOpen={handleCancelOpen}
      handleCancelClose={handleCancelClose}
      cancelOpen={cancelOpen}
      handleInit={handleInit}
      open={open}
      page2={page2}
      setPage2={setPage2}
      handleEditorStateChange={handleEditorStateChange}
      editorState={editorState}
      handleFilePath={handleFilePath}
      handleSelectAll={handleSelectAll}
      handleUnSelectAll={handleUnSelectAll}
    />
  )
}

export default MailPage
